.hero {

  &__indicators-wrapper {
    display: none;
  }

  &__carousel {
    &--nkoad {
      .hero-banner__text-content {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
