.content-section {
  padding-bottom: 20px;

  &__header {
    margin-bottom: 8px;
  }

  &--arrow + .content-section {
    .content-section__header {
      padding-top: 64px;
    }
  }

  &__header-breadcrumbs {
    display: none;
  }
  &__header-title {
    line-height: 1;
    margin-bottom: 40px;
    &.location{
      width: 160px;
    }
  }
}
