@keyframes scrollArrowIndicator {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  25% {
    opacity: .5;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(50%);
  }
  75%{
    opacity: .5;
    transform: translateY(100%);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes scrollIconIndicator {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0;
  }
  10%,
  50%,
  90% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
