.scroll-indicator {
  height: 48px;
  left: 50%;
  position: fixed;
  top: calc(100vh - 100px);
  transform: translateX(-50%);
  width: 28px;
  z-index: 5;

  &__arrow-container {
    animation-duration: 1s;
    animation-fill-mode: backwards;
    animation-iteration-count: infinite;
    animation-name: scrollArrowIndicator;
    animation-timing-function: linear;
    height: 14px;
    width: 14px;
    position: absolute;
    top: 10px;
    z-index: 2;
  }
  &__arrow {
    height: 12px;
    width: 12px;
    border-right: $white 3px solid;
    border-bottom: $white 3px solid;
    left: 50%;
    margin-left: 9px;
    border-radius: 2px;
    transform: rotate(45deg);
  }

  &__icon {
    animation-duration: 1.5s;
    animation-fill-mode: backwards;
    animation-iteration-count: infinite;
    animation-name: scrollIconIndicator;
    animation-timing-function: linear;
    height: 48px;
    position: absolute;
    width: 29px;
    z-index: 1;
    border: $white 3px solid;
    border-radius: 18px;
  }
}
