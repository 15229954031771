.tabs{
  &__item-title{
    font-size: 15px;
    &::after {
      bottom: -12px;
      width: 100%;
      background: $grey--dark;
    }
  }
  &__item {
    margin-bottom: 0;
    padding-bottom: 24px;
    &--active, &.--mouse-active {
      .tabs__item-title{
        &::after {
          background: $grey;
        }
        .content-section--gray & ,
        .content-section--light-gray & {
          &::after {
            background: $white;
          }
        }
      }
    }
    .tabs__item-title{
      .content-section--gray & ,
      .content-section--light-gray & {
        &::after {
          background: $grey--dark;
        }
      }
    }
  }
}
.tabs-desktop {
  display: none;
}

.tabs-mobile {
  display: block;
  .tabs{
    &__item-title{
      color: $grey;
      &::after {
        width: 0 !important;
        .--tabs-animate &{
          width: 100% !important;
          background: $grey;
        }
      }
    }
  }
}
