.map {
  height: 700px;
  flex-direction: column;

  &__text {
    width: 100%;
    justify-content: flex-start;
  }
  &__canvas {
    width: 100%;
    margin-top: 0;
  }
}
