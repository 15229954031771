/**************
* Colour classes
  * Helper classes for each colour
***************/

//Loop through colours and create various classes
@for $i from 1 through length($colours) {
	$colour: nth($colours, $i);
	$name: nth($colour-names, $i);

  //Font colour
	.#{$name},
  .color--#{$name} {
		color: $colour;
	}

  //Background colour
  .bg-#{$name},
  .bg--#{$name} {
		background-color: $colour;
	}

  //Background radial gradient
  .gradient-#{$name},
  .gradient--#{$name} {
    background: radial-gradient(ellipse at center, lighten($colour, 20) 0%, $colour 80%);
	}

  //Font colour on hover
  .hover-#{$name},
  .hover--#{$name} {
    transition: color 0.4s, stroke 0.4s;
    h3, p{
      transition: color 0.4s, stroke 0.4s;
    }
    &:hover{
      color: $colour;
      h3, p{
        color: $colour;
      }
      svg path{
        stroke: $colour;
      }
    }
	}

  //Background colour on hover
  .hover-bg-#{$name},
  .hover-bg--#{$name} {
    transition: background-color 0.4s;
    &:hover{
      background-color: $colour;
    }
	}

  //Border colour
  .border-#{$name},
  .border--#{$name} {
		border-color: $colour;
	}

  // SVG styling
  .stroke--#{$name} {
    stroke: $colour;
  }

}
