.navigation {
  position: fixed;
  left: 60%;
  right: 0;
  top: 45px;
  bottom: 0;
  background: $grey--light;
  z-index: 10;
  display: none;
  flex-direction: column;
  justify-content: center;

  &__container {
    width: 100%;
    max-width: 960px;
    margin: 20px auto;
    padding-left: 80px;
  }

  &__list {
    list-style: none;
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    padding: 0;
    &-bottom {
      position: absolute;
      bottom: 40px;
      padding: 0;
      list-style: none;
      font-size: 13px;
      .navigation__item a {
        text-decoration: underline;
      }
      .device-mobile & {
        display: none;
      }
    }
  }
  &__item {
    color: $grey;
    &--active,
    &:hover {
      color: $white;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    &--labs {
      margin-top: 24px;
    }
    &--subnav {
      > span {
        cursor: pointer;
      }
      svg {
        fill: $grey;
        margin-bottom: -1px;
        margin-left: 8px;
        transition: transform .5s;
        cursor: pointer;
      }
      &:hover svg {
        fill: $white;
        transform: rotate(90deg);
      }
      &.navigation__item--active {
        color: $white;
        svg {
          fill: $white;
          transform: rotate(90deg);
        }
        .navigation__list {
          display: block;
          .navigation__item {
            line-height: 18px;
            padding: 5px 0;
          }
          .navigation__item--active {
            opacity: 1;
          }
        }
      }
      .navigation__list {
        padding: 20px 0 20px 40px;
        display: none;
        .navigation__item {
          color: $white;
          opacity: .7;
          &:hover {
            color: $white;
            opacity: 1;
          }
        }
      }
    }
  }
  &__button {
    position: absolute;
    right: 18px;
    top: 18px;
    width: 18px;
    height: 18px;
    background: url(../images/icons/close.svg) center center no-repeat;
    cursor: pointer;
  }
  &__languages {
    text-transform: uppercase;
    font-weight: bold;
    h2 {
      font-size: 22px;
      color: $black;
      margin: 0;
      padding-left: 40px;
    }
    .navigation__list {
      margin-top: 0;
    }
    .navigation__item {
      display: inline-block;
      padding: 0 12px 0 16px;
      border-left: $black 3px solid;
      line-height: 20px;
      &:first-child {
        border-left: 0;
        padding-left: 0;
      }
    }
  }
}
