.team-new {

  .team__container {
    width: 100%;
  }
  /*--slick--*/
  .slick-list {
    height: auto !important;
  }
}
