/**************
* General styles
  * Text
  * Headings
  * Links
  * Generic helper classes
***************/



* {
  box-sizing: border-box;
}

body{
  background: $white;
  color: $grey; // $black
  min-width: 320px;
  min-height: 100vh;
  font-size: 16px;
  font-family: $Montserrat;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

.container {
  max-width: 1074px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
