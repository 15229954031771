.gallery {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    &__item  {
        min-width: 131px;
        height: auto;
        position: relative;
        transition:1.3s;
        img {
            //position: absolute;
            //left: 0;
            //top: 0;
            transition: transform 1.3s;
            //border: solid 1px $white;
            z-index: 1;
        }
        .description{
            position: absolute;
            z-index: 3;
            left: 0;
            right: 0;
            bottom: 10px;
            font-weight: bold;
            font-size: 30px;
            text-align: center;
            color: $grey--dark;
            transition: .4s;
        }
        &:hover {
            z-index: 4;
            img {
                transform: scale(1.25);
                z-index: 4;
            }
            .description {
                color: transparent;
            }
        }
    }
}


.gallery-wrapper {
    width: 100%;
    height: 80vh;
    //overflow: hidden;
    position: relative;
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .content-section--black & {
        border-top: 1px solid $white;
        border-bottom: 1px solid $white;
    }
}
.slick-list {
    width: 100%;
    //margin-left: -10vw;
    padding:0 !important;
    .gallery__item{
        //padding-bottom: 140px;
        img {
            position: inherit;
            margin: 0 auto;
            height: auto;
            width: 90%;
        }
    }
}

.slick-slide {
    //opacity: 0;
    transition: opacity 0.2s ease;
    >div{
        width: 100%;
    }
     .gallery__item {
      transform: scale(0.6);
      transition: transform 0.5s ease;
      height: auto;
      padding:  0 0 140px;

      .music-videos & {
        transform: scale(0.85);
      }

      
    }
    &:focus {
      outline: none;
    }
}
.slick-arrow {
    position: absolute;
    top:0;
    bottom: 0;
    width: 100px;
    color: transparent;
    border: 0;
    cursor: pointer;
    z-index: 1;
    transition: background-color .4s;
    background-position: 50% calc(50% - 110px);
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 30px 54px;
    overflow: hidden;
    &:hover {
        opacity: 1;
        //background-color: rgba($white, .8);
    }
    /*&::before{
        content: "";
        position: absolute;
        top: 46%;
        width: 48px;
        height: 48px;
        border-right: $grey--dark 8px solid;
        border-top: $grey--dark 8px solid;
        border-radius: 5px;
        transform: rotate(45deg);
        .content-section--black & {
            border-right: $white 8px solid;
            border-top: $white 8px solid;
        }
    }*/
    &.slick-prev{
        left:0;
        background-image: url('../images/icons/carousel-arr-left.svg');
        /*&::before{
            transform: rotate(-135deg);
        }*/
    }
    &.slick-next{
        right:0;
        background-image: url('../images/icons/carousel-arr-right.svg');
    }
}

.fancybox__backdrop {
    background: rgba(255,255,255, .8) !important;
}

.fancybox__nav .f-button {
    height: 100px;
    width: 100px;
    &:hover {
        background-color: transparent !important;
    }
    &::before {
        content: "";
        top: 0 !important;
        right: unset !important;
        left: unset !important;
        right: unset !important;
        bottom: unset !important;
        width: 70px;
        height: 70px;
        border-right: 1px solid #0d496e;
        border-top: 1px solid #0d496e;
        transform: rotate(55deg) translate(-48%,-16%) skew(25deg);
    } 
    &.is-prev::before {
        transform: rotate(-125deg) translate(52%,16%) skew(25deg);
    }
    svg {
        display: none;
    }

}