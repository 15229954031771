.media {
  height: calc(100% + 20px);
  margin-bottom: -20px;
  margin-top: 0;

  &__image-mobile {
    display: block;
  }

  &__image-mobile + .media__video,
  &__image-mobile + .image-cover {
    display: none;
  }

  &__content {
    width: calc(100% - 30px);

    .testimonial {
      left: 0;
      margin-right: 15px;
      right: 20px;
    }
  }

  &__copyright {
    right: 8px;
  }
}
