.locations {
  //margin-bottom: 65px;
  //margin-bottom: 326px;

  &__map-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__map {
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    width: 820px;
    height: 405px;
    z-index: 1;
  }

  &__pointer-wrapper {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
  }

  &__pointer-link-title {
    display: block;
    font-family: $Montserrat;
    font-size: 10px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 10px; // 1.15;
    letter-spacing: -.1px;
    text-align: left;
    color: $white;
    padding: 12px 26px 0; //12px 26px;
    text-transform: uppercase;
  }

  &__pointer-link {
    position: absolute;
    display: inline-block;
    width: 197.971px;
    height: 33.001px;
    z-index: 2;
    text-decoration: none;

    > svg {

      path {
        fill: $grey;
      }
    }

    &--animate-top {
      opacity: 0;
      animation: topHide 1s linear;

      transform: rotate(-40deg);
    }

    &--animate-bottom {
      opacity: 0;
      animation: bottomHide 1s linear;
      transform: rotate(140deg);

      .locations__pointer-link-title {
        text-align: right;
        transform: rotate(-180deg);
      }
    }

    &--red {

      > svg {

        path {
          fill: $red;
        }
      }
    }
  }

  &--visible {

    .locations__pointer-link {

      &--animate-top {
        opacity: 1;
        //animation: topShow 1s linear;

        &:nth-child(1n),
        &:nth-child(3n),
        &:nth-child(6n) {
          animation: topShow .3s linear;
        }
        &:nth-child(2n),
        &:nth-child(5n),
        &:nth-child(8n) {
          animation: topShow .7s linear;
        }
        &:nth-child(4n),
        &:nth-child(7n),
        &:nth-child(9n) {
          animation: topShow .5s linear;
        }
      }

      &--animate-bottom {
        opacity: 1;

        &:nth-child(1n),
        &:nth-child(3n),
        &:nth-child(6n) {
          animation: bottomShow .7s linear;
        }
        &:nth-child(2n),
        &:nth-child(5n),
        &:nth-child(8n) {
          animation: bottomShow .3s linear;
        }
        &:nth-child(4n),
        &:nth-child(7n),
        &:nth-child(9n) {
          animation: bottomShow .5s linear;
        }
        //animation: bottomShow 1s linear;
      }
    }
  }
}
