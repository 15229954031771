.columns {
  display: block;

  &__column {
    &:nth-child(1),
    &:nth-child(2),
    &:only-child {
      display: block;
      padding: 0;
      width: 100%;
    }
  }

  + .vacancies {
    margin-top: 85px;
  }
}
