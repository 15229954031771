.locations {
  margin-bottom: 150px;

  &__map {
    display: none;
  }

  &__pointer-wrapper {
    position: relative;
    margin-top: 15px;
  }

  &__pointer-link {
    position: relative;
    display: block;
    top: 0 !important;
    left: -20px !important;
    transform: rotate(-40deg);
    margin-bottom: 20px;


    &--animate-top,
    &--animate-bottom {
      animation: none;
      opacity: 1;

      &:nth-child(odd),
      &:nth-child(even) {
        animation: none;
      }

      .locations__pointer-link-title {
        text-align: left;
        transform: rotate(0deg);
      }

      > svg {
        position: absolute;
        top: 0;
        left: 0;
        transform: none;
      }
    }
  }

  &__pointer-link-title {
    font-size: 12px;
    padding: 10px 46px;
  }

  &--visible {

    .locations__pointer-link {
      &--animate-top,
      &--animate-bottom {
        animation: none;

        &:nth-child(odd),
        &:nth-child(even) {
          animation: none;
        }
      }
    }
  }
}
