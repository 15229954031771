.counters {
  overflow: hidden;

  &__container {
    display: flex;
    max-width: 870px;
    width: 100%;
    padding: 15px;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__one {
    position: relative;
    color: $white;
    text-align: center;
    width: 254px;
    min-height: 413px;
  }

  &__background {
    position: absolute;
    width: 348px;
    height: 413px;
    left: 0;
    top: 0;
    background: url(../images/counter.png) left top no-repeat;
    z-index: 1;
  }

  &__description {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    position: relative;
    z-index: 2;
    h2 {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0 0 40px;
    }
  }

  &__number {
    font-weight: 900;
    font-size: 78px;
    color: $grey--light;
    height: 132px;
    padding: 86px 0 72px;
    position: relative;
    z-index: 2;
    box-sizing: content-box;
  }
  &__image {
    height: 132px;
    padding: 86px 0 72px;
    position: relative;
    z-index: 2;
    box-sizing: content-box;
    img {
      width: auto;
      max-height: 100%;
      height: 80px;
    }
  }
}
