.clients {
  padding: 83px 0 93px;


  &__items {
    min-width: 500vw;
    height: 400px;
  }
  &__item {
    width: calc(100vw - 80px);
    height: 90px
  }
}
