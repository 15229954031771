.slick-arrow {
    &:hover {
        opacity: 1;
        //background-color: rgba($white, .8);
    }
    &::before{
        width: 40px;
        height: 40px;
    }
    &.slick-next::before {
        right: 20px;
    }
}
.fancybox__nav .f-button {
    height: 70px;
    width: 70px;
    &::before {
        width: 40px;
        height: 40px;
    }
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    &__wrapper {
        padding-top: 0;
    }
    &__item  {
        .description{
            font-size: 21px;
        }
        padding-bottom: 160px !important;
    }
    .slick-arrow {
        
        &.slick-prev{
            background-position: left calc(50% - 40px);
        }
        &.slick-next{
            background-position: right calc(50% - 40px);
        }
    }
}