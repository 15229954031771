.tabs {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    display: block;
    padding-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    &--mouse-active {
      .tabs__item-title::after {
        width: 100%;
      }
    }
  }

  &__item--active {

    .tabs__item-title {
      color: $grey;
      transition: color .3s linear;

      &::after {
        height: 11px;
        //width: 100%;
        transition: width .5s linear;
      }

      .content-section--gray & ,
      .content-section--light-gray & {
        color: $white;
        transition: color .3s linear;

        &::after {
          background: $white;
          transition: width .5s linear;
        }
      }
    }
  }

  &__item-title {
    display: inline-block;
    color: $grey--dark;
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.2px;
    line-height: 1.25;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    transition: color .3s linear;

    &::after {
      position: absolute;
      display: inline-block;
      content: '';
      width: 0;
      height: 11px;
      background: $grey;
      transition: width .5s linear;
      bottom: -17px;
      left: 0;
    }

    .content-section--gray & ,
    .content-section--light-gray & {
      &::after {
        background: $white;
        transition: width .5s linear;
      }
    }

    &:hover {
      cursor: pointer;
      color: $grey;
      transition: color .3s linear;

      &::after {
        height: 11px;
        width: 100%;
        transition: width .5s linear;
      }

      .content-section--gray & ,
      .content-section--light-gray & {
        color: $white;
        transition: width .5s linear;
      }
    }

    .content-section--gray & {
      color: $grey--light;
    }

    .content-section--light-gray & {
      color: $grey;
    }
  }

}

.tabs-desktop {

}

.tabs-mobile {
  display: none;
}
