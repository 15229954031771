.swipe-icon {
  animation-duration: 3s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
  animation-name: swipeAnimation;
  animation-timing-function: linear;
  display: block;
  margin: 0 auto;
  width: 60px;
  opacity: .5;
}
