.clients {
  overflow: hidden;
  width: 100%;

  &__items {
    font-size: 0;
    width: 100%;
  }

  &__item {
    display: inline-block;
    height: 115px;
    margin-bottom: 10px;
    margin-left: 1.5%;
    margin-right: 1.5%;
    text-align: center;
    width: 17%;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
    filter: gray;
    -webkit-filter: grayscale(99%);
    -webkit-backface-visibility: hidden;
    transition: all .8s;
    &:hover {
      filter: none;
    }
  }

  &__item-image {
    vertical-align: middle;
    /*width: 60%;*/
  }

  &__swipe-icon {
    display: none;
  }
}
