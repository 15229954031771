.media {
  height: 100%;
  width: 100%;
  background: $black;
  overflow: hidden;

  &__image-wrapper {
    position: relative;
    z-index: 1;
  }

  &__image {
    display: block;
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    max-width: none;
  }

  &__image-mobile {
    display: none;

    > img {
      display: block;
      height: 100%;
      min-width: 100%;
      object-fit: cover;
      max-width: none;
    }
  }

  &__video-wrapper {
    position: relative;
    z-index: 1;
  }

  &__video {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;

    .generic-video__media {
      display: block;
      height: 100%;
      min-width: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    max-width: 1260px;
    left: 50%;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 2;
    transform: translateX(-50%);

    .testimonial {
      left: 50%;
      margin-left: 0;
    }
  }

  &__copyright {
    bottom: 23px;
    color: $white;
    font-family: $Montserrat;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 2.75;
    position: absolute;
    right: 22px;
    text-align: right;
  }

  &--paralax {
    .image-cover {
      transform: scale(2);
    }
  }
}
