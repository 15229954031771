@keyframes hideTab {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
@keyframes showTab {
  0% {
    display: none;
    opacity: 0;
  }
  99% {
    display: block;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
