.tabs-content {

  &__item {
    display: none;
    //animation: hideTab 1s linear;
    //animation-fill-mode: forwards;

    &--active {
      display: block;
      animation: showTab 1s linear;
      animation-fill-mode: forwards;
    }
  }
}
