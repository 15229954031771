.hero {
  display: block;
  height: 100vh;
  overflow: hidden;
  width: 100%;

  &__carousel {
    position: relative;

    &--nkoad {
      .hero-banner__text-content {
        font-family: $Montserrat;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: -.2px;
        text-align: left;
        color: $white;
        line-height: 24px;
      }
    }
  }

  &__indicators-wrapper {
    bottom: 30px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    z-index: 10;

    .slick-dots {
      display: block;
      margin: 0;
      padding: 0;

      > li {
        background: $white65;
        border-radius: 50%;
        display: inline-block;
        height: 8px;
        margin-right: 6px;
        width: 8px;

        &.slick-active {
          background: $white;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          cursor: pointer;
        }

        > button {
          display: none;
        }
      }
    }
  }

}
