.footer {
  background: $grey;
  color: $grey--dark;
  padding: 48px 15px;
  position: relative;
  z-index: 2;

  &__container {
    margin: 0 auto;
    max-width: 1620px;
    position: relative;

    p {
      color: $grey--dark;
      font-size: 13px;
      line-height: 1.29;
      font-weight: normal;
      margin: 0 0 18px;
    }
    a {
      color: inherit;
    }
  }

  &__logo {
    display: block;
    height: 30px;
    margin-bottom: 36px;
    max-width: 100px;
    img {
      display: block;
      height: 100%;
    }
  }

  &__copy {
    font-size: 13px;
    line-height: 1.29;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 10px;
  }

  &__social {
    // float: right;
    margin-left: 12px;
    position: absolute;
    right: 0;
    text-align: right;

    a {
      display: inline-block;
      width: 30px;
      height: 30px;
      img {
        display: block;
        height: 100%;
      }
    }
  }

  &__social-terms {
    display: block;
    margin-top: 30px;
  }

  &__social-terms > a {
    color: #939389;
    display: block !important;
    text-decoration: underline;
    width: 100%;
    height: auto;
    font-size: 13px;
    margin-bottom: 5px;

    &:hover {
      text-decoration: none;
    }
  }
}
