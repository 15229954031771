.counters {
  overflow-x: auto;
  &__container {
    //display: block;
    //text-align: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    min-width: 870px;
  }
  &__one {
    display: inline-block;
    vertical-align: top;
    margin: 0 12px 24px;
  }
}
