.breadcrumbs {

  &__menu {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__menu-item {
    display: inline-block;
    font-family: $Montserrat;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: -0.1px;
    text-align: left;

    &:last-child {
      .breadcrumbs__menu-item-no-link {
        color: $grey;

        .content-section--gray & ,
        .content-section--light-gray & ,
        .content-section--dark-gray & {
          color: $white;
        }
      }
    }
  }

  &__menu-item-link {
      color: $grey--dark;
      .content-section--gray & ,
      .content-section--light-gray & ,
      .content-section--dark-gray & {
        color: rgba($white, .5);
      }
  }

  &__menu-item-no-link {
    color: $grey;
    //border-bottom: 1px $grey solid;

    .content-section--gray & ,
    .content-section--light-gray & {
      color: $white;
      //border-bottom: 1px $white solid;
    }
  }

  &__menu-item + &__menu-item {

    &::before {
      display: inline-block;
      content: ' | ';
      margin: 0 5px;
      color: $grey--dark;
      .content-section--gray & ,
      .content-section--light-gray & ,
      .content-section--dark-gray & {
        color: $white;
      }
    }
  }
}
