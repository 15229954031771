.cta {
  font-family: $Montserrat;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 1.25;
  text-align: left;
  margin-bottom: 45px;

  &__link,
  &__caption {
    border-bottom: 11px solid $grey;
    color: $grey;
    padding-bottom: 6px;
    text-decoration: none;

    .content-section--light-gray & ,
    .content-section--gray & {
      color: $white;
      border-bottom: 11px solid $white;
    }
  }

  &__caption {
    display: inline-block;
  }
}
