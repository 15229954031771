.get-in-touch {
    position: relative;
    background-color: $orange;
    color: $white;
    padding: 84px 0;
    font-size: 18px;
    font-weight: bold;

    &__container {
        max-width: 1074px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__text {
       p {
        margin:0;
       } 
       a {
        color: inherit;
        &:hover {
            text-decoration: none;
        }
       }
    }
    &__icon {
        width: 124px;
        margin-left: 8px;
        a  {
            display: block;
            width: 124px;
            height: 124px;
            border-radius: 62px;
            background: $white url("../images/icons/contact_us.svg") center center no-repeat;
            background-size: contain;
        }
    }
}