.team {


  &__container {
    display: inline-block;
    width: auto;
  }
  &__member {
    display: block;
    margin: 0 auto 20px;

    &--show {

      .team__member__photo:before {
        display: none;
      }

      .team__member__info {
        display: none;
      }
    }
    &:hover {
      .team__member__photo:before {
        display: block;
        animation: none;
      }

      .team__member__info {
        display: block;
        animation: fadeTop .6s 1 cubic-bezier(.77, 0, .175, 1);
      }
    }
  }
}
