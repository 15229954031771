.process {
  max-width: 100%;
  position: relative;

  &__wrapper {
    min-width: 870px;
    max-width: 1280px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 0 25px;
  }

  &__images-wrapper {
    margin-bottom: 20px;
    padding: 0 12px;
  }

  &__image {
    width: 100%;
  }

  &__images-wrapper,
  &__text-items {
    min-width: 870px;
    margin: 0 auto;
  }

  &__text-items {
    display: flex;
    padding: 0 15px;
    padding: 0;
  }

  &__text-item {
    margin: 19px 14px;
    text-align: center;
    width: 33.33%; //20%;

    &:first-child {
      margin-left: 26px;
    }
    &:last-child {
      margin-right: 61px;
    }
    &:last-child:nth-child(5) {
      margin-right: 43px;
    }
  }

  &__text-item-title {
    display: block;
    font-family: $Montserrat;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 1.21;
    margin-bottom: 40px;
  }

  &__text-item-description {
    display: block;
    font-family: $Montserrat;
    font-size: 12px;
    letter-spacing: -0.1px;
    line-height: 1.25;
  }

  &__swipe-icon {
    display: none !important;
  }
}
