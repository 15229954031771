.richtext {
  font-family: $Montserrat;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.19;
  letter-spacing: -0.2px;
  text-align: left;
  line-height: 19px;
  //color: $black;

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 35px;
    margin-top: 0;
  }

  h2 {
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    text-align: left;
    line-height: 19px;
    color: $grey--dark;
    margin-bottom: 25px;

    .content-section--light-gray & {
      color: $grey;
    }
  }

  h6 {
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: -0.2px;
    text-align: left;
    color: #c0c0be;
    text-transform: none;
    margin-bottom: 25px;
  }

  p {
    margin-top: 0;
    margin-bottom: 25px;
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  strong,
  b {
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    letter-spacing: -0.2px;
    text-align: left;
    line-height: 19px;
  }

  hr {
    padding: 25px 0;
    border: 0;
    border-bottom: 1px solid $grey;

    .content-section--light-gray & ,
    .content-section--gray & {
      border-color: $white;
    }
  }

  table {
    border-spacing: 0px;
    border-collapse: collapse;

    th {
      background: $grey;
      color: $white;
      padding: 10px 25px;
      text-align: center;

      .content-section--light-gray & ,
      .content-section--gray & {
        color: $grey;
        background: $white;
      }
    }

    tr {

      &:nth-child(even) {
        background: $grey--light;

        .content-section--light-gray & {
          background: $grey;
        }

      }

      td {
        border: 0;
        padding: 10px 25px;
        text-align: center;
      }
    }
  }

  .uppercase {
    text-transform: uppercase;
    color: $grey--light;
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    letter-spacing: -0.2px;
    text-align: left;

    .content-section--light-gray & {
      color: $white;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      .content-section & {
        color: $grey--blackish;
      }

      .content-section--gray & {
        color: $white;
      }
    }
  }

  .wellcom {
    font-family: $Montserrat;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    //color: $grey--light;
    line-height: 24px;

    .content-section--light-gray & {
      color: $white;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      .content-section & {
        color: $grey--blackish;
      }

      .content-section--gray & {
        color: $white;
      }
    }
  }

  .attention-big {
    color: $red-dark;
    line-height: 24px;
    font-family: $Montserrat;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.09;
    letter-spacing: -0.2px;
  }

  & + .cta {
    margin-top: 35px;
  }

  img {
    display: inline-block;
    max-width: 230px;
    vertical-align: middle;

    &:first-child {
      margin-left: 0;
    }
  }
  img + img {
    display: inline-block;
    margin-left: 30px;
  }
  .coloured-text {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }
}
