.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 45px;
  z-index: 19;

  //&--darken {
    background: $grey;
    background: linear-gradient(to right, $grey--light, $grey);
    //animation: fadeInBg .6s 1 cubic-bezier(.77, 0, .175, 1);
    //transition: background .6s;
  //}

  &__container {
    margin: 0 auto;
    max-width: 1620px;
    position: relative;
  }

  &__logo {
    animation: fadeLeft 1.2s 1 cubic-bezier(.77, 0, .175, 1);
    display: inline-block;
    height: 18px;
    margin-top: 16px;
    position: relative;
    z-index: 12;
    margin-left: 15px;
    img {
      display: block;
      height: 100%;
    }
  }

  &__email {
    position: absolute;
    left: calc(60% + 80px);
    top: 16px;
    font-size: 12px;
    color: $white;
    &:hover {
      text-decoration: none;
    }
  }

  &__nav-trigger {
    animation: fadeIn 2s 1 cubic-bezier(.77, 0, .175, 1);
    background: none;
    border: 0;
    cursor: pointer;
    height: 42px;
    margin-top: -16px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 50px;
    z-index: 11;
    outline: none;

    span {
      background: $white;
      border-radius: 50%;
      display: block;
      height: 4px;
      left: 50%;
      margin: -3px 0 0 -3px;
      position: absolute;
      top: 50%;
      width: 4px;

      &::before {
        background: $white;
        border-radius: 50%;
        content: '';
        display: block;
        height: 4px;
        margin: 0 0 0 -7px;
        transition: margin .3s;
        width: 4px;
      }

      &::after {
        background: $white;
        border-radius: 50%;
        content: '';
        display: block;
        height: 4px;
        margin: -4px 0 0 7px;
        transition: margin .3s;
        width: 4px;
      }
    }
  }

  &--active {
    .navigation {
      display: flex;
      animation: fadeIn .5s 1 cubic-bezier(.77, 0, .175, 1);
    }
    .header__nav-trigger {
      span {

        &::before {
          margin: -7px 0 0;
        }

        &::after {
          margin: 10px 0 0;
        }
      }
    }
  }
}
