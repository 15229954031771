.cookie-message {
  position: fixed;
  z-index: 33;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 36px 15px;
  background: $orange;
  display: none;
  &--visible {
    display: block;
  }
  &__container {
    position: relative;
    padding-right: 24px;
    max-width: 840px;
    margin: 0 auto;
    font-size: 16px;
    h2 {
      display: inline-block;
      color: $white;
      margin: 0 12px 0 0;
      text-transform: uppercase;
      font-size: 12px;
    }
    p {
      display: inline-block;
      color: $white;
    }
  }
  &__button {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -9px;
    width: 18px;
    height: 18px;
    background: url(../images/icons/close-white.svg) center center no-repeat;
    cursor: pointer;
  }
}
