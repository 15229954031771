.process {

  &__wrapper {
    width: auto;
    overflow-x: auto;
    padding: 0 15px;
  }

  &__images-wrapper {
    margin-bottom: 20px;
  }

  &__swipe-icon {
    display: block !important;
  }
}
