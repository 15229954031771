.clients-gallery {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    &__item  {
        display: flex;
        min-width: 131px;
        height: auto;
        position: relative;
        transition:1.3s;
        .description{
            position: relative;
            background-color: $orange;
            color: $white;
            font-size: 22px;
            font-weight: 700;
            line-height: 24px;
            padding: 72px 100px;
            margin: 0 120px 100px;
            &__author {
              color: $grey;
              text-transform: uppercase;
            }
            &::after {
                content: "";
                position: absolute;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 100px 100px 0 0;
                border-color: #EB9827 transparent transparent transparent;
                transform: rotate(0deg);
                bottom: -99px;
                left: 0;
            }
        }
    }
    .slick-arrow {
        background-position: 50% calc(50% - 54px);
        &.slick-prev{
            background-image: url('../images/icons/carousel-arr-left-white.svg');
        }
        &.slick-next{
            background-image: url('../images/icons/carousel-arr-right-white.svg');
        }
    }
}
.slider-nav {
    margin: 0 auto;
    .slick-dots {
      display: block;
      margin: 0;
      padding: 0;

      > li {
        background: $white65;
        border-radius: 50%;
        display: inline-block;
        height: 8px;
        margin-right: 6px;
        width: 8px;

        &.slick-active {
          background: $white;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          cursor: pointer;
        }

        > button {
          display: none;
        }
      }
    }
}

.clients-gallery__swipe-icon {
    display: none;
}