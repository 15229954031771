.hero-banner {
  height: 100vh;
  width: 100%;
  position: relative;
  display: block;
  background: $black;

  &__image {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__video {

    .generic-video__media {
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }
    background-color: $black;
  }

  &__image-mobile {
    display: none;
  }

  &__content {
    position: relative;
    max-width: 1620px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 2;
  }

  &__text {
    color: $white;
    left: 0;
    min-height: 100%;
    position: relative;
    top: 0;
    width: 100%;
  }

  &__text-title {
    color: $white;
    border-left: 12px solid $white;
    padding-left: 12px;
    margin: 0 24px 0 12px;
    font-family: $Montserrat;
    font-size: 36px;
    font-weight: 800;
    line-height: 0.9;
    position: absolute;
    top: 64px;
    text-transform: uppercase;
    display: flex;
    &.hero-banner__text-title--has-subtitle {
      color: $grey--dark;
      border-color: $grey--dark;
    }
    .home__carousel & {
      font-size: 28px;
    }
    .error__carousel & {
      border-left: 0 !important;
      color: white !important;
      font-size: 18px;
      .oops {
        font-size: 72px;
      }
    }
  }
  &__text-subtitle {
    color: $white;
    border-left: 12px solid $white;
    padding-left: 12px;
    margin-left: 24px;
    font-family: $Montserrat;
    font-size: 36px;
    font-weight: 800;
    line-height: 0.9;
    text-transform: uppercase;
    .error__carousel & {
      font-size: 18px;
      line-height: 1.1;
    }
  }

  &__text-content {
    color: $white;
    font-family: $Montserrat;
    font-size: 40px;
    font-weight: bold;
    left: 19.25%;
    letter-spacing: -0.4px;
    line-height: 0.83;
    max-width: 400px;
    position: absolute;
    text-align: left;
    text-shadow: 0px 3px 7px $black35;
    top: 50%;
    transform: translateY(-50%);
  }

  &__brand-logo {
    bottom: 30px;
    left: 19.25%;
    position: absolute;
  }

  &__brand-logo-image {
    width: auto;
    height: 38px;
  }

  &__copyright {
    bottom: 23px;
    color: $white;
    font-family: $Montserrat;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: -0.1px;
    line-height: 2.75;
    position: absolute;
    right: 73px;
    text-align: right;
  }
}
