.team-new {
  .team__wrapper {
    overflow-x: hidden;
    margin: 0 auto;
    max-width: 1260px;
    position: relative;
    transition: all .5s;
  }
  .team__container {
    display: block;
    max-width: 900px;
    margin: 0 auto;
    position: static;
  }
  .team__member {
    height: auto;
    border-radius: 132px;
    white-space: normal;
    width: 264px !important;
    margin: 0 auto;
    display: block !important;
    &__photo {
      width: 264px;
      height: 264px;
      border-radius: 132px;
      position: relative;
      &:before {
        background: none;
        /*new 30-08-2018*/
        background: $red-team;
      }
      /*new 30-08-2018*/
      img {
        display: none;
      }
    }
    &__name, &__function {
      margin: 0;
      text-transform: uppercase;
    }
    &__text {
      color: $white;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      margin: 28px 0;
    }
    &__social {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
      a {
        padding: 7px;
        background: #fff;
        border-radius: 50%;
      }
    }
    &__info {
      position: relative;
      background: $red-team;
      padding: 30px;
      margin-top: 84px;
      text-transform: none;
      /*new 30-08-2018*/
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom:0;
      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 57.5px 40px 57.5px;
        border-color: transparent transparent $red-team transparent;
        position: absolute;
        left: 50%;
        margin-left: -57px;
        top: -40px;
      }
    }
    &:hover {
      .team__member__info {
        display: block;
        /*new 30-08-2018*/
        //animation: fadeTop .6s 1 ease;
      }
    }
  }
  /*--slick--*/
  .slick-arrow {
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    color: transparent;
    overflow: hidden;
    position: absolute;
    top: 124px;
    width: 26px;
    height: 60px;
    cursor: pointer;
    z-index: 3;
  }
  .slick-prev {
    left: 20px;
    background-image: url(../images/carousel-arr-left.png);
  }
  .slick-next {
    right: 20px;
    background-image: url(../images/carousel-arr-right.png);
  }

}
