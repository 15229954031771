.teams-model-types {
  .counters__container{
    display: block;
    width: auto;
    min-width: 0;
    white-space: normal;
    .counters__one {
      text-align: left;
      width: auto;
      min-height: 0;
      margin: 0 0 28px;
      .counters__description {
        text-align: left;
        h2 {
          margin: 0 0 24px;
        }
      }
    }
  }
  .counters__image, .counters__background {
    display:none;
  }
}
