.map {
  height: 700px;
  overflow: hidden;
  display: flex;

  .content-section--free .container & {
    padding-bottom: 100px;
  }

  &__text {
    background-color: $grey;
    color: $white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    padding: 15px;
    &-content {
      width: 507px;
    }
    a {
      color: inherit;
    }
  }
  &__canvas {
    height: calc(100% + 80px);
    margin: -40px;
    margin-left: 0;
    width: 50%;

    &--grayscale {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
      filter: gray;
      -webkit-filter: grayscale(99%);
      -webkit-backface-visibility: hidden;
    }
  }
}
