.columns {
  display: flex;

  &__column {

    &:nth-child(1) {
      display: inline-block;
      padding-right: 40px;
      vertical-align: top;
      width: 52%;
    }

    &:nth-child(2) {
      display: inline-block;
      padding-left: 22px;
      vertical-align: top;
      width: 48%;
    }

    &:only-child {
      width: 100%;
    }
  }

  + .vacancies {
    margin-top: 100px;
  }

  &__column-back-top-wrapper {
    margin-top: 65px;
    display: block;
  }

  &__column-back-top {
    color: #4e5759;
  }
}
