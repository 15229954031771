@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }

  50% {
    opacity: .4;
    transform: translateX(10%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes fadeInBg {
  0% {
    background: rgba($grey,0);
  }

  100% {
    background: rgba($grey,1);
  }
}

