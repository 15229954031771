.clients-gallery {
    &__item  {
        .description{
            margin: 0 2px 100px;
            padding: 54px 40px;
            font-size: 16px;
            line-height: 18px;
            &::after {
                border-width: 70px 70px 0 0;
                bottom: -69px;
            }
        }
    }
    .slick-arrow {
        display: none !important;
    }
}
.clients-gallery__swipe-icon {
    display: block;
}