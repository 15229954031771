.header-title {
  padding: 80px 15px 35px;
  color: #525859;
  background: $grey--light;

  &--grey {
    background: $grey--light;
    color: $white;
  }

  &__caption {
    color: $grey;
    font-family: $Montserrat;
    font-size: 36px;
    font-weight: 800;
    line-height: 0.95;
    text-transform: uppercase;
    margin: 0;
    border-left: 12px solid $grey;
    padding-left: 12px;
    margin-left: 12px;
  }

  > .container {
    max-width: 870px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;

    .content-section__header {
      margin-bottom: 0;
    }
  }
}

.header-title + .content-section {
  .container {
    max-width: 870px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;

    .content-section__header {
      margin-bottom: 0;
    }
  }
}
