@keyframes swipeAnimation {
  0% {
    transform: translateX(0%);
  }
  5% {
    transform: translateX(-10%);
  }
  10%,
  50%,
  90% {
    transform: translateX(0%);
  }
  95% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0%);
  }
}
