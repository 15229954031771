.who-we-are-tiles {
    position: relative;
    color: $white;
    padding: 84px 0;
    font-size: 16px;
    font-weight: 600;

    &__tile {
        padding: 13px 62px 13px 13px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        background-color: $grey--dark;
        border-radius: 64px;
    }
    &__text {
        text-align: right;
    }
    &__icon {
        width: 98px;
        height: 98px;
        background-color: $white;
        border-radius: 50%;
        box-shadow: 4px 4px 6px rgba($black, .4);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 24px;
            height: 24px;
        }
    }
}