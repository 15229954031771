.vacancies {


  &__mail-link-wrapper {
    display: flex;
    align-items: center;
  }

  &__mail-link-icon {
    display: inline-block;
    background: $grey--light;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    position: relative;
    margin-right: 10px;

    > svg {
      width: 25px;
      height: auto;
      margin-left: 50%;
      margin-top: 50%;
      transform: translate(-50%, -50%);

      .icon-color {
        fill: $white;
      }
    }
  }

  &__mail-link {
    display: inline-block;
    color: $grey;
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.19;
    letter-spacing: -0.2px;
    text-decoration: none;
  }
}
