/**************
* Variables
  * Colour definition
  * Shadows
  * Fonts
***************/

$white: #FFFFFF;
$black: #000000;
$black--light: #525859;
$grey: #525859;
$grey--light: #bcc5c3;
$grey--dark: #97998f;
$red: #bc0433;
$red-dark: #bf2336;
$red-team: #c8002e;
$blue: #2f3b53;
$orange: #eb9827;

$colours: $white $black;
$colour-names: white black;

$light-shadow: rgba(0, 0, 0, .2) 0 2px 4px;
$black10: rgba(0, 0, 0, .1);
$black20: rgba(0, 0, 0, .2);
$black30: rgba(0, 0, 0, .3);
$black35: rgba(0, 0, 0, .35);
$black50: rgba(0, 0, 0, .5);
$white65: rgba(255, 255, 255, .65);

$grey--blackish: #454d4e;
$white--darken: #eaeaea;

/***************
  FONT VARIABLES
***************/
$Montserrat: Montserrat, Arial, sans-serif;
