
.hero-banner {
  &__text-title,
  &__text-subtitle {
    font-size: 24px;
    border-width: 10px;
    }
  &__image {
    .image-cover {
      box-shadow: inset 0px 130px 100px $black;
    }
  }
}