@keyframes topHide {
  0% {
    opacity: 1;
    transform: translate(0, 0) rotate(-40deg);
  }
  25% {
    opacity: .5;
    transform: translate(50px, -50px) rotate(-40deg);
  }
  50% {
    opacity: .15;
    transform: translate(150px, -150px) rotate(-40deg);
  }
  75% {
    opacity: 0;
    transform: translate(250px, -250px) rotate(-40deg);
  }
  100% {
    opacity: 0;
    transform: translate(300px, -300px) rotate(-40deg);
  }
}

@keyframes bottomHide {
  0% {
    opacity: 1;
    transform: translate(0, 0) rotate(140deg);
  }
  25% {
    opacity: 5;
    transform: translate(-50px, 50px) rotate(140deg);
  }
  50% {
    opacity: .15;
    transform: translate(-150px, 150px) rotate(140deg);
  }
  75% {
    opacity: 0;
    transform: translate(-250px, 250px) rotate(140deg);
  }
  100% {
    opacity: 0;
    transform: translate(-300px, 300px) rotate(140deg);
  }
}

@keyframes topShow {
  0% {
    opacity: 0;
    transform: translate(300px, -300px) rotate(-40deg);
  }
  25% {
    opacity: 0;
    transform: translate(250px, -250px) rotate(-40deg);
  }
  50% {
    opacity: .15;
    transform: translate(150px, -150px) rotate(-40deg);
  }
  75% {
    opacity: 5;
    transform: translate(50px, -50px) rotate(-40deg);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) rotate(-40deg);
  }
}

@keyframes bottomShow {
  0% {
    opacity: 0;
    transform: translate(-300px, 300px) rotate(140deg);
  }
  25% {
    opacity: 0;
    transform: translate(-250px, 250px) rotate(140deg);
  }
  50% {
    opacity: .15;
    transform: translate(-150px, 150px) rotate(140deg);
  }
  75% {
    opacity: 5;
    transform: translate(-50px, 50px) rotate(140deg);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) rotate(140deg);
  }
}
