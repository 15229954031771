.clients {

  &__wrapper {
    max-width: 100%;
    overflow-x: auto;
  }

  &__items {
    margin: 0 auto;
    overflow-y: hidden;
    overflow-x: auto;
  }

  &__item {
    width: calc(50vw - 30px);
    margin-left: 0;
    margin-right: 0;
  }

  &__item-image {
    max-width: 50%;
    width: auto;
  }

  &__swipe-icon {
    display: block !important;
    margin-bottom: -50px;

    > svg {
      .st0 {
        stroke: $grey
      }
    }
  }
}
