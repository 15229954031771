.content-section {
  position: relative;
  background: $white;
  border-top-color: $white;
  min-height: 100vh;
  //z-index: 1;
  padding-bottom: 200px;

  &--mobile,
  &--free {
    min-height: 30vh;

    .content-section__parallax-wrapper {
      min-height: 30vh;
    }

    .content-section__parallax {
      min-height: 30vh;
    }
    .content-section__header {
      margin-bottom: 0;
    }
  }

  &--media {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &--nkoad {
    .richtext {
      .wellcom {
        border-bottom: 11px solid $white;
      }
    }
  }

  &__parallax-wrapper {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
  }

  &__parallax {
    position: relative;
    width: 100%;
    min-height: 100vh;

    &--fixed {
      position: fixed;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }

    &--stacked {
      .content-section__header {
        margin-bottom: 0px;
        margin-top: 80px;
      }
    }
  }

  &__header {
    font-size: 0px;
    margin-bottom: 170px;
    padding-top: 10px;
    width: 100%;
    transition: all .4s;
  }

  &__header-title {
    color: $grey--dark;
    display: inline-block;
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.2px;
    line-height: 1.19;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 170px;
    

    .content-section--light-gray &,
    .content-section--dark-gray & {
      color: $white;
    }
  }

  &__header-breadcrumbs {
    font-size: 12px;
    padding: 15px 0;
  }

  &--gray {
    background: $grey;
    border-color: $grey;
    border-top-color: $grey;
    color: $white;
  }

  &--light-gray {
    background: $grey--light;
    border-top-color: $grey--light;
    color: $white;
  }
  &--dark-gray {
    background: $grey--dark;
    border-top-color: $grey--dark;
    color: $white;
  }

  &--blue {
    background: $blue;
    border-color: $grey;
    border-top-color: $grey;
    color: $white;
  }
  
  &--white-darken {
    background: $white--darken;
    border-color: $white--darken;
    border-top-color: $white--darken;
    color: $grey--dark;
  }

  &--arrow {
    &::after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: inherit;
      border-width: 40px 54px 0 54px;
      transform: translate(-50%, 0px);
      z-index: 2;
    }
  }

  &--low-margin .content-section__header {
    margin-bottom: 0;
  }
  .scroll-indicator {
    position: absolute;
    bottom: 30px;
    top: auto;
    &__arrow,
    &__icon {
      border-color: $grey--dark;
    }
  }
}
