/**************
* Combine partials
***************/

@charset 'UTF-8';

@import 'mixins/module';

@import 'vendor/_normalize.scss';
@import 'node_modules/slick-carousel/slick/slick.scss';

@import 'base/_variables.scss';
@import 'base/_colours.scss';
@import 'base/_grid-settings.scss';
@import 'base/_general.scss';

//@import 'partials/_grid.scss';

// components

// components xl:scss
@import "../components/c001__header/scss/header.xl";
@import "../components/c002__navigation/scss/navigation.xl";
@import "../components/c003__footer/scss/footer.xl";
@import "../components/c004__hero/scss/hero.xl";
@import "../components/c005__hero-banner/scss/hero-banner.xl";
@import "../components/c006__image-cover/scss/image-cover.xl";
@import "../components/c007__generic-video/scss/generic-video.xl";
@import "../components/c008__columns/scss/columns.xl";
@import "../components/c009__content-section/scss/content-section.xl";
@import "../components/c010__breadcrumbs/scss/breadcrumbs.xl";
@import "../components/c011__cta/scss/cta.xl";
@import "../components/c012__richtext/scss/richtext.xl";
@import "../components/c013__tabs/scss/tabs.xl";
@import "../components/c014__counters/scss/counters.xl";
@import "../components/c015__media/scss/media.xl";
@import "../components/c016__team/scss/team.xl";
@import "../components/c018__locations/scss/locations.xl";
@import "../components/c019__vacancies/scss/vacancies.xl";
@import "../components/c020__vacancy/scss/vacancy.xl";
@import "../components/c021__map/scss/map.xl";
@import "../components/c022__testimonial/scss/testimonial.xl";
@import "../components/c023__clients/scss/clients.xl";
@import "../components/c024__process/scss/process.xl";
@import "../components/c025__tabs-content/scss/tabs-content.xl";
@import "../components/c026__swipe-icon/scss/swipe-icon.xl";
@import "../components/c027__scroll-indicator/scss/scroll-indicator.xl";
@import "../components/c028__teams-model-types/scss/teams-model-types.xl";
@import "../components/c029__team-new/scss/team-new.xl";
@import "../components/c030__cookie-message/scss/cookie-message.xl";
@import "../components/c031__header-title/scss/header-title.xl";
@import "../components/c032__footer-nkoad/scss/footer.xl";
@import "../components/c033__get-in-touch/scss/get-in-touch.xl";
@import "../components/c034__who-we-are-tiles/scss/who-we-are-tiles.xl";
@import "../components/c035__projects-gallery/scss/projects-gallery.xl";
@import "../components/c036__clients-gallery/scss/clients-gallery.xl";
// endinject

@media (max-width: #{map-get($grid-breakpoints, 'xl') - 1}) {
  // components lg:scss
  @import "../components/c001__header/scss/header.lg";
  @import "../components/c002__navigation/scss/navigation.lg";
  @import "../components/c003__footer/scss/footer.lg";
  @import "../components/c004__hero/scss/hero.lg";
  @import "../components/c005__hero-banner/scss/hero-banner.lg";
  @import "../components/c006__image-cover/scss/image-cover.lg";
  @import "../components/c007__generic-video/scss/generic-video.lg";
  @import "../components/c008__columns/scss/columns.lg";
  @import "../components/c009__content-section/scss/content-section.lg";
  @import "../components/c010__breadcrumbs/scss/breadcrumbs.lg";
  @import "../components/c011__cta/scss/cta.lg";
  @import "../components/c012__richtext/scss/richtext.lg";
  @import "../components/c013__tabs/scss/tabs.lg";
  @import "../components/c014__counters/scss/counters.lg";
  @import "../components/c015__media/scss/media.lg";
  @import "../components/c016__team/scss/team.lg";
  @import "../components/c018__locations/scss/locations.lg";
  @import "../components/c019__vacancies/scss/vacancies.lg";
  @import "../components/c020__vacancy/scss/vacancy.lg";
  @import "../components/c021__map/scss/map.lg";
  @import "../components/c022__testimonial/scss/testimonial.lg";
  @import "../components/c023__clients/scss/clients.lg";
  @import "../components/c024__process/scss/process.lg";
  @import "../components/c025__tabs-content/scss/tabs-content.lg";
  @import "../components/c026__swipe-icon/scss/swipe-icon.lg";
  @import "../components/c027__scroll-indicator/scss/scroll-indicator.lg";
  @import "../components/c028__teams-model-types/scss/teams-model-types.lg";
  @import "../components/c029__team-new/scss/team-new.lg";
  @import "../components/c030__cookie-message/scss/cookie-message.lg";
  @import "../components/c031__header-title/scss/header-title.lg";
  @import "../components/c032__footer-nkoad/scss/footer.lg";
  @import "../components/c033__get-in-touch/scss/get-in-touch.lg";
  @import "../components/c034__who-we-are-tiles/scss/who-we-are-tiles.lg";
  @import "../components/c035__projects-gallery/scss/projects-gallery.lg";
  @import "../components/c036__clients-gallery/scss/clients-gallery.lg";
  // endinject
}

@media (max-width: #{map-get($grid-breakpoints, 'lg') - 1}) {
  // components md:scss
  @import "../components/c001__header/scss/header.md";
  @import "../components/c002__navigation/scss/navigation.md";
  @import "../components/c003__footer/scss/footer.md";
  @import "../components/c004__hero/scss/hero.md";
  @import "../components/c005__hero-banner/scss/hero-banner.md";
  @import "../components/c006__image-cover/scss/image-cover.md";
  @import "../components/c007__generic-video/scss/generic-video.md";
  @import "../components/c008__columns/scss/columns.md";
  @import "../components/c009__content-section/scss/content-section.md";
  @import "../components/c010__breadcrumbs/scss/breadcrumbs.md";
  @import "../components/c011__cta/scss/cta.md";
  @import "../components/c012__richtext/scss/richtext.md";
  @import "../components/c013__tabs/scss/tabs.md";
  @import "../components/c014__counters/scss/counters.md";
  @import "../components/c015__media/scss/media.md";
  @import "../components/c016__team/scss/team.md";
  @import "../components/c018__locations/scss/locations.md";
  @import "../components/c019__vacancies/scss/vacancies.md";
  @import "../components/c020__vacancy/scss/vacancy.md";
  @import "../components/c021__map/scss/map.md";
  @import "../components/c022__testimonial/scss/testimonial.md";
  @import "../components/c023__clients/scss/clients.md";
  @import "../components/c024__process/scss/process.md";
  @import "../components/c025__tabs-content/scss/tabs-content.md";
  @import "../components/c026__swipe-icon/scss/swipe-icon.md";
  @import "../components/c027__scroll-indicator/scss/scroll-indicator.md";
  @import "../components/c028__teams-model-types/scss/teams-model-types.md";
  @import "../components/c029__team-new/scss/team-new.md";
  @import "../components/c030__cookie-message/scss/cookie-message.md";
  @import "../components/c031__header-title/scss/header-title.md";
  @import "../components/c032__footer-nkoad/scss/footer.md";
  @import "../components/c033__get-in-touch/scss/get-in-touch.md";
  @import "../components/c034__who-we-are-tiles/scss/who-we-are-tiles.md";
  @import "../components/c035__projects-gallery/scss/projects-gallery.md";
  @import "../components/c036__clients-gallery/scss/clients-gallery.md";
  // endinject
}

@media (max-width: #{map-get($grid-breakpoints, 'md') - 1}) {
  // components sm:scss
  @import "../components/c001__header/scss/header.sm";
  @import "../components/c002__navigation/scss/navigation.sm";
  @import "../components/c003__footer/scss/footer.sm";
  @import "../components/c004__hero/scss/hero.sm";
  @import "../components/c005__hero-banner/scss/hero-banner.sm";
  @import "../components/c006__image-cover/scss/image-cover.sm";
  @import "../components/c007__generic-video/scss/generic-video.sm";
  @import "../components/c008__columns/scss/columns.sm";
  @import "../components/c009__content-section/scss/content-section.sm";
  @import "../components/c010__breadcrumbs/scss/breadcrumbs.sm";
  @import "../components/c011__cta/scss/cta.sm";
  @import "../components/c012__richtext/scss/richtext.sm";
  @import "../components/c013__tabs/scss/tabs.sm";
  @import "../components/c014__counters/scss/counters.sm";
  @import "../components/c015__media/scss/media.sm";
  @import "../components/c016__team/scss/team.sm";
  @import "../components/c018__locations/scss/locations.sm";
  @import "../components/c019__vacancies/scss/vacancies.sm";
  @import "../components/c020__vacancy/scss/vacancy.sm";
  @import "../components/c021__map/scss/map.sm";
  @import "../components/c022__testimonial/scss/testimonial.sm";
  @import "../components/c023__clients/scss/clients.sm";
  @import "../components/c024__process/scss/process.sm";
  @import "../components/c025__tabs-content/scss/tabs-content.sm";
  @import "../components/c026__swipe-icon/scss/swipe-icon.sm";
  @import "../components/c027__scroll-indicator/scss/scroll-indicator.sm";
  @import "../components/c028__teams-model-types/scss/teams-model-types.sm";
  @import "../components/c029__team-new/scss/team-new.sm";
  @import "../components/c030__cookie-message/scss/cookie-message.sm";
  @import "../components/c031__header-title/scss/header-title.sm";
  @import "../components/c032__footer-nkoad/scss/footer.sm";
  @import "../components/c033__get-in-touch/scss/get-in-touch.sm";
  @import "../components/c034__who-we-are-tiles/scss/who-we-are-tiles.sm";
  @import "../components/c035__projects-gallery/scss/projects-gallery.sm";
  @import "../components/c036__clients-gallery/scss/clients-gallery.sm";
  // endinject
}

@media (max-width: #{map-get($grid-breakpoints, 'sm') - 1}) {
  // components xs:scss
  @import "../components/c001__header/scss/header.xs";
  @import "../components/c002__navigation/scss/navigation.xs";
  @import "../components/c003__footer/scss/footer.xs";
  @import "../components/c004__hero/scss/hero.xs";
  @import "../components/c005__hero-banner/scss/hero-banner.xs";
  @import "../components/c006__image-cover/scss/image-cover.xs";
  @import "../components/c007__generic-video/scss/generic-video.xs";
  @import "../components/c008__columns/scss/columns.xs";
  @import "../components/c009__content-section/scss/content-section.xs";
  @import "../components/c010__breadcrumbs/scss/breadcrumbs.xs";
  @import "../components/c011__cta/scss/cta.xs";
  @import "../components/c012__richtext/scss/richtext.xs";
  @import "../components/c013__tabs/scss/tabs.xs";
  @import "../components/c014__counters/scss/counters.xs";
  @import "../components/c015__media/scss/media.xs";
  @import "../components/c016__team/scss/team.xs";
  @import "../components/c018__locations/scss/locations.xs";
  @import "../components/c019__vacancies/scss/vacancies.xs";
  @import "../components/c020__vacancy/scss/vacancy.xs";
  @import "../components/c021__map/scss/map.xs";
  @import "../components/c022__testimonial/scss/testimonial.xs";
  @import "../components/c023__clients/scss/clients.xs";
  @import "../components/c024__process/scss/process.xs";
  @import "../components/c025__tabs-content/scss/tabs-content.xs";
  @import "../components/c026__swipe-icon/scss/swipe-icon.xs";
  @import "../components/c027__scroll-indicator/scss/scroll-indicator.xs";
  @import "../components/c028__teams-model-types/scss/teams-model-types.xs";
  @import "../components/c029__team-new/scss/team-new.xs";
  @import "../components/c030__cookie-message/scss/cookie-message.xs";
  @import "../components/c031__header-title/scss/header-title.xs";
  @import "../components/c032__footer-nkoad/scss/footer.xs";
  @import "../components/c033__get-in-touch/scss/get-in-touch.xs";
  @import "../components/c034__who-we-are-tiles/scss/who-we-are-tiles.xs";
  @import "../components/c035__projects-gallery/scss/projects-gallery.xs";
  @import "../components/c036__clients-gallery/scss/clients-gallery.xs";
  // endinject
}

// components animations:scss
@import "../components/c001__header/scss/header.animations";
@import "../components/c002__navigation/scss/navigation.animations";
@import "../components/c003__footer/scss/footer.animations";
@import "../components/c004__hero/scss/hero.animations";
@import "../components/c005__hero-banner/scss/hero-banner.animations";
@import "../components/c006__image-cover/scss/image-cover.animations";
@import "../components/c007__generic-video/scss/generic-video.animations";
@import "../components/c008__columns/scss/columns.animations";
@import "../components/c009__content-section/scss/content-section.animations";
@import "../components/c010__breadcrumbs/scss/breadcrumbs.animations";
@import "../components/c011__cta/scss/cta.animations";
@import "../components/c012__richtext/scss/richtext.animations";
@import "../components/c013__tabs/scss/tabs.animations";
@import "../components/c014__counters/scss/counters.animations";
@import "../components/c015__media/scss/media.animations";
@import "../components/c016__team/scss/team.animations";
@import "../components/c018__locations/scss/locations.animations";
@import "../components/c019__vacancies/scss/vacancies.animations";
@import "../components/c020__vacancy/scss/vacancy.animations";
@import "../components/c021__map/scss/map.animations";
@import "../components/c022__testimonial/scss/testimonial.animations";
@import "../components/c023__clients/scss/clients.animations";
@import "../components/c024__process/scss/process.animations";
@import "../components/c025__tabs-content/scss/tabs-content.animations";
@import "../components/c026__swipe-icon/scss/swipe-icon.animations";
@import "../components/c027__scroll-indicator/scss/scroll-indicator.animations";
@import "../components/c028__teams-model-types/scss/teams-model-types.animations";
@import "../components/c029__team-new/scss/team-new.animations";
@import "../components/c030__cookie-message/scss/cookie-message.animations";
@import "../components/c031__header-title/scss/header-title.animations";
@import "../components/c032__footer-nkoad/scss/footer.animations";
@import "../components/c033__get-in-touch/scss/get-in-touch.animations";
@import "../components/c034__who-we-are-tiles/scss/who-we-are-tiles.animations";
@import "../components/c035__projects-gallery/scss/projects-gallery.animations";
@import "../components/c036__clients-gallery/scss/clients-gallery.animations";
// endinject
