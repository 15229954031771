.hero-banner {

  &__image-mobile {
    display: block;
    .home__carousel & {
      background-position: right bottom !important;
    }
  }

  &__video {
    display: none;
  }

  .hero-banner__image-mobile + .hero-banner__video,
  .hero-banner__image-mobile + .image-cover {
    display: none;
  }

  &__text {
    right: 25px;
  }

  &__text-content {
    font-size: 18px;
    left: 0;
    max-width: 100%;
    text-align: center;
    //top: 70%;
    //transform: translateY(-70%);
    width: 100%;
  }

  &__text-title,
  &__text-subtitle {
    font-size: 30px;
    .home__carousel & {
      font-size: 24px;
    }
  }

  &__brand-logo {
    display: none;
  }

  &__copyright {
    display: none;
  }

}
