.counters {
  &__container {

    display: block;
    white-space: nowrap;
    margin-top: 60px;
  }
  &__one {
    margin: 0 16px 24px;
    width: calc(100vw - 64px);
    white-space: normal;
  }
  &__background{
    left: 50%;
    transform: translateX(-40%);
  }
  &__description{
    margin-bottom: 24px;
  }
}
