.richtext {
  font-size: 14px;
  line-height: 1.21;
  letter-spacing: -0.1px;

  .wellcom {
    font-size: 18px;
    line-height: 1.06;
    letter-spacing: -0.2px;
  }
}
