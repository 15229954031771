.testimonial {
  max-width: 400px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 15px;

  &__wrapper {
    background: $red;
    color: $white;
    padding: 30px 30px 100px;
  }

  &__content {
    .richtext {
      font-family: $Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.22;
      letter-spacing: -0.2px;
      text-align: left;
    }
  }

  &__arrow {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 30px;
    border-color: transparent transparent #bc0432 transparent;
    margin-right: -30px;
  }

}
