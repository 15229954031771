.team {

  &__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  &__container {
    display: flex;
    max-width: 890px;
    width: 100%;
    padding: 15px;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-x: auto;
  }

  &__member {
    width: 264px;
    height: 264px;
    border-radius: 132px;
    position: relative;
    text-align: center;
    overflow: hidden;
    margin: 0 10px 10px;

    &__photo {
      width: 100%;
      height: 100%;

      &::before {
        content: "";
        position: absolute;
        z-index: 4;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: transparent;
        transition: all 1s;
      }

      img {
        height: 100%;
        position: relative;
        z-index: 2;
      }
    }

    &__info {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 6;
      font-weight: bold;
      font-size: 14px;
      color: $white--darken;
      text-transform: uppercase;
    }

    &__name {
      margin: 126px 20px 0;
    }
    &__function {
      margin: 0 20px;
    }

    &__social {
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: 20px;

      a {
        margin: 0 6px;
        display: inline-block;
      }

      img {
        width: 32px;
      }
    }
    &--show {

      .team__member__photo:before {
        background: rgba($grey, .75);
      }

      .team__member__info {
        display: block;
        animation: fadeTop 1.2s 1 ease;
      }
    }
  }
  .swipe-icon {
    margin-top: 12px;
     .st0 {
       stroke: $grey;
    }
  }
}
