.hero {
  &__carousel {
    position: relative;

    &--nkoad {
      .hero-banner__text-content {
        font-size: 18px;
        padding-left: 31px;
        padding-right: 31px;
      }
    }
  }
}
