@keyframes fadeTop {
  0% {
    opacity: 0;
    transform: translatey(100%);
  }

  50% {
    opacity: .4;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
