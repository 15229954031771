.process {

  &__wrapper {
    min-width: auto;
  }

  .design-image {
    margin-left: calc(50vw - 150px);
    img{
      margin-right: calc(50vw - 150px);
    }
    
    .process__image {
      width: 80%;
    }
    .process__text-item {
      width: 21.33%;
    }
  }

  .build-image {
    margin-left: calc(50vw - 120px);
    img{
      margin-right: calc(50vw - 120px);

    }
  }
  .prototyping-image {
    margin-left: calc(50vw - 120px);
    img{
      margin-right: calc(50vw - 120px);

    }
  }

  &__text-item-description {
    display: none;
  }
}
